<template>
    <div class="container">
        <b-row>
            <b-col md='6' lg='8'>
                    <span class='mb-3'>Skontaktuj się z nami celem rozpoczęcia współpracy</span>
                <b-form class='form'>
                    <label>Imię i Nazwisko</label>
                    <b-form-input 
                        type="text" 
                        v-model="name"
                        name="name"
                        placeholder="Imię i Nazwisko"
                    />
                    <label>Adres Email</label>
                    <b-form-input 
                        type="email" 
                        v-model="email"
                        name="email"
                        placeholder="Adres Email"
                        />
                    <label>Treść Wiadomości</label>
                    <b-form-textarea
                        name="message"
                        v-model="message"
                        cols="30" rows="5"
                        placeholder="Treść Wiadomości">
                    </b-form-textarea>
                    
                    <b-button @click="sendEmail" variant="primary">Wyślij wiadomość</b-button>
                    <p class='success' v-if="success">Wiadmość wysłana pomyślnie</p>
                    <p class='failure' v-if="failure">Wystąpił błąd podczas wysyłania wiadomośći</p>
                </b-form>
            </b-col>
            <b-col md='4' lg='4'>
                <p>Kontakt możesz także zrealizować pod nr telefonu:<br/>
                    Telefon: <a href="tel:+48511229287">(+48)511 22 92 87</a><br/>
                    Email: <a href="mailto:silverdesign@wp.pl">silverdesign@wp.pl</a>
                </p>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import emailjs from 'emailjs-com';

export default {
  name: 'ContactUs',
  data() {
    return {
      name: '',
      email: '',
      message: '',
      success: false,
      failure: false,
    }
  },
  methods: {
    async sendEmail() {
        this.success = false;
        this.failure = false;
        emailjs.send("service_i81aqtp","template_xgu4qql",{
            from_name: this.name,
            from_email: this.email,
            message: this.message,  
        }, 'user_MbVYLhBzhNdMBTIYWaq0Z')
        .then(() =>
            this.success = true
        , () =>
            this.failure = true
        );
      // Reset form field
      this.name = ''
      this.email = ''
      this.message = ''
    },
  }
}
</script>

<style scoped>

.form{
  text-align: center;
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 10px 20px;
  width: 100%;
    margin-top: 10px;
    margin-bottom: 15px;
}

.success{
    color: green;
    font-size: 0.9rem;
    text-align: center !important;
    margin-top: 7px;
}

.failure{
    color: red;
    font-size: 0.9rem;
    text-align: center !important;
    margin-top: 7px;
}

label {
  float: left;
}

input[type=text], [type=email], textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}

input[type=submit] {
  background-color: #4CAF50;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type=submit]:hover {
  background-color: #45a049;
}
</style>